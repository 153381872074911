/* HEADER */
.header {
  width: 60%;
  margin: 0 auto;
  height: 55px; /* Consistent height */
  line-height: 55px; /* Consistent line-height */
  position: sticky;
  top: 10px;
  left: 0;
  right: 0;
  background: var(--header-background);
  color: var(--header-text-color);
  border-radius: 25px;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;
  padding: 0 15px;
}

.light-theme {
  --header-background: #f7f7f7;
  --header-text-color: #000;
  --link-active: #000;
}

.dark-theme {
  --header-background: #0c123d;
  --header-text-color: #fff;
  --link-active: #fff;
}

.nav__wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 1rem; /* Set uniform gap */
  list-style: none;
}

.menu__link {
  font-weight: 400;
  font-size: 0.8rem; /* Smaller text */
  color: var(--header-text-color);
  text-decoration: none;
  padding: 5px 10px; /* Smaller padding */
  border-radius: 15px; /* Compact and rounded */
  transition: color 0.3s ease;
}

.section-link {
  color: var(--primary-color);
}

.light-theme .menu__link {
  color: #000;
}

.menu__link:hover {
  color: var(--link-active);
}

.logo {
  display: flex;
  align-items: center;
}

.logo__image {
  width: 20px; /* Smaller logo */
  height: auto;
  margin-right: 5px;
}

.logo h2 {
  color: var(--header-text-color);
  font-size: 1rem; /* Smaller font size */
}

.light__mode span {
  color: var(--header-text-color);
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  font-size: 0.8rem; /* Smaller text */
  cursor: pointer;
}

.header__shrink {
  width: 60%;
  height: 55px; /* Consistent height */
  line-height: 55px; /* Consistent line-height */
  position: sticky;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 9999;
  background: var(--header-background);
  border-radius: 25px; /* Maintain compact shape */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.light-theme .header__shrink {
  box-shadow: 2px 2px 10px -2px #ddd;
}

.mobile__menu {
  color: var(--header-text-color);
  font-size: 1.3rem;
  cursor: pointer;
  display: none;
}

.light-theme .mobile__menu {
  color: #000;
}

/* Responsive */
@media only screen and (max-width: 992px) {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 15.5rem; /* Adjusted to match header height */
    left: 0;
    width: 100%;
    column-gap: 2px;
    background: var(--menu-background);
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
    z-index: 999;
    border-radius: 8px;
  }

  .menu__active {
    display: flex;
    transform: translateY(0);
  }

  .menu__item {
    margin: 0;
    height: 3rem;
  }

  .menu__item__hide {
    display: none;
  }

  .mobile__menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 80%;
    height: 50px;
    line-height: 50px;
    border-radius: 20px;
  }

  .mobile__menu {
    font-size: 1.2rem;
  }

  .logo h2 {
    font-size: 0.9rem;
  }

  /* Shift the Get Started button to the right in the mobile dropdown */
  .get__started__button {
    margin-left: 20px; /* Adjust margin as needed */
  }
}

/* Light and Dark Mode Menu Backgrounds */
.light-theme .menu {
  --menu-background: rgb(233, 236, 255);
}

.dark-theme .menu {
  --menu-background: #000;
}

/* Get Started Button */
.get__started__button .menu__link {
  padding: 5px 15px; /* Smaller padding */
  border-radius: 15px; /* Align with design */
  background-image: linear-gradient(to right, #4852db, #6f74dd);
  color: #fff;
  transition: background-image 0.3s ease;
}

.get__started__button .menu__item__hide {
  padding: 5px 15px; /* Smaller padding */
  border-radius: 15px; /* Align with design */
  background-image: linear-gradient(to right, #4852db, #6f74dd);
  color: #fff;
  transition: background-image 0.3s ease;
}

.get__started__button .menu__link:hover {
  background-image: linear-gradient(to right, #7554ff, #8a6eff);
}

.get__started__button {
  background-color: none;
  border: none;
  width: 8rem;
  padding-left: 0.5rem;
}