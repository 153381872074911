/* SERVICE */

.service__top-content {
  text-align: center;
  margin-bottom: 70px;
}

.services__item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  margin-bottom: -6rem;
}

.services__item {
  background: var(--card-bg);
  width: 25%;
  padding: 30px 15px;
  text-align: center;
  border-radius: 0.5rem;
  cursor: pointer;
  min-height: 300px; /* Ensuring consistent height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.service__icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: var(--icon-bg);
  border-radius: 5px;
  margin-bottom: 30px;
  border: 1px solid var(--primary-color);
}

.service__icon--analytics {
  margin-bottom: 30px; /* Adjust this value to move the icon up */
}

.service__title {
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 30px;
}
.description--analytics {
  margin-bottom: 20px; /* Adjust this value to move the description up */
}


.service__title--analytics {
  margin-bottom: 68px; /* Adjust this value to move the text up */
}

.light-theme .service__icon {
  background-color: var(--primary-color);
}

.light-theme .service__icon i {
  color: #fff;
}

.service__icon i {
  font-size: 2rem;
  color: var(--primary-color);
}

@media only screen and (max-width: 992px) {
  .service__top-content h2 {
    font-size: 1.5rem;
  }

  .services__item-wrapper {
    flex-wrap: wrap;
  }
  .services__item {
    width: 30%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .services__item {
    width: 47.8%;
  }
  .service__title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .services__item {
    width: 100%;
  }
}
