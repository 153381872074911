/* FOOTER */

.footer {
  padding: 30px 0; /* Reduced padding */
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Adjusted gap between elements */
}

.footer__logo {
  width: 40%;
}

.footer__quick-links {
  width: 20%;
}

.footer__logo h2 {
  color: var(--primary-color);
  font-size: 1.3rem; /* Slightly reduced font size */
  margin-bottom: 10px; /* Reduced margin */
}

.small__text {
  font-size: 0.85rem; /* Slightly reduced font size */
  margin-top: 15px; /* Reduced margin */
}

.quick__link-title {
  font-size: 0.9rem; /* Slightly reduced font size */
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 15px; /* Reduced margin */
}

.quick__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.quick__link-item {
  margin-bottom: 15px; /* Reduced margin */
}

.quick__link-item a {
  font-size: 0.8rem; /* Further reduced font size */
  font-weight: 300;
  cursor: pointer;
}

.quick__link-item a:hover {
  color: var(--primary-color);
}

.copyright {
  color: rgba(255, 255, 255, 0.58);
  margin-top: 15px; /* Reduced margin */
  text-align: center;
  font-size: 0.8rem; /* Further reduced font size */
  font-weight: 400;
}

.light-theme .quick__link-item a {
  font-weight: 500;
}

.light-theme .copyright {
  color: #555;
}

.popup {
  position: fixed;
  background: var(--primary-color);
  color: white;
  padding: 6px 12px; /* Further reduced padding */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeInOut 2s forwards;
  transform: translate(-50%, -50%);
  max-width: 90%; /* Adjust width for smaller screens */
  left: 50%;
  bottom: 20px; /* Adjust as needed */
  font-size: 0.75rem; /* Further reduced font size */
  height: 32px; /* Reduced height */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(20px);
  }
}

@media only screen and (max-width: 608px) {
  .footer__logo {
    width: 50%;
  }

  .footer__wrapper {
    flex-wrap: wrap;
    gap: 0.5rem !important; /* Adjusted gap */
    margin-left: 10px;
  }

  .footer__logo h2 {
    font-size: 1.2rem; /* Further reduced font size */
  }

  .popup {
    bottom: 20px; /* Adjust as needed */
    transform: translateX(-50%);
    padding: 4px 10px; /* Further reduced padding */
    font-size: 0.75rem; /* Further reduced font size */
    height: 32px; /* Reduced height */
  }
}

@media only screen and (max-width: 576px) {
  .footer__quick-links,
  .footer__logo {
    width: 47%;
  }
}