/* HERO */

.hero__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center content vertically */

}

.hero__content,
.hero__img {
  width: 50%; 
}

.hero__img img {
  width: 100%;
}

.hero__content h2 {
  font-size: 3rem;
  line-height: 65px;
}

.hero__content p {
  margin-top: 40px;
  font-size: 1.1rem;
}

.hero__content {
  padding-top: 40px;
}

.highlight {
  color: var(--primary-color);
}

.hero__btns {
  margin-top: 40px;
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.primary__btn,
.secondary__btn {
  padding: 0.8rem 1.5rem;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;
}

.primary__btn {
  background: var(--btn-secondary-bg);
  border: 1px solid var(--btn-primary-bg);
}

.secondary__btn {
  background: var(--btn-primary-bg);
}

@media only screen and (max-width: 992px) {
  .hero__content h2 {
    font-size: 1.8rem;
    line-height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .hero__content h2 {
    font-size: 1.5rem;
    line-height: 45px;
  }

  .hero__content {
    font-size: 0.9rem;
  }

  .primary__btn,
  .secondary__btn {
    font-size: 0.8rem;
  }

  .hero__content,
  .hero__img {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .hero__content,
  .hero__img {
    width: 100%;
  }
  
  .hero__wrapper {
    flex-direction: column;
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
  }

  .hero__img {
    margin-top: 45px; /* Adjust as needed */
  }

  .hero__content h2 {
    font-size: 2.5rem; /* Increase font size for emphasis */
    line-height: 1; /* Reset line height */
    margin-bottom: 10px; /* Add spacing between title and other content */
  }

  .hero__btns {
    margin-top: 20px; /* Adjust as needed */
    display: flex;
    justify-content: center; /* Center buttons horizontally */
  }
}




