/* COUNTER */

.counter__quote {
  margin-top: -5px;
  text-align: center;
  background: var(--quote-bg-color); /* Background gradient */
  padding: 20px 30px;
  border-radius: 0.5rem;
}

.quote__text {
  font-size: 2.2rem;
  font-weight: 800; /* Increased font weight */
  color: #fff;
  margin: 0; /* Remove default margin */
  background: linear-gradient(90deg, #5b86e5, #36d1dc);  /* Dark theme gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.4;
  margin-bottom: -60px;
}

.light-theme .quote__text {
  background: linear-gradient(90deg, #ffffff, #ffc8a7, #ffffff); /* Light theme gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2px;
}

@media only screen and (max-width: 992px) {
  .quote__text {
    font-size: 1.3rem; /* Adjusted font size for medium screens */
  }
}

@media only screen and (max-width: 768px) {
  .quote__text {
    font-size: 1.1rem; /* Adjusted font size for smaller screens */
  }
}

@media only screen and (max-width: 576px) {
  .quote__text {
    font-size: 1.3rem; /* Adjusted font size for mobile screens */
  }
}
