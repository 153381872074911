/* TESTIMONIALS */

.slider__content-top {
  text-align: center;
  margin-bottom: 850px;
}

.slider__wrapper {
  width: 70%;
  margin: auto;
  margin-top: -820px;
}

.slider__item {
  background: var(--testimonial-bg);
  padding: 40px 30px;
  border-radius: 1rem;
  text-align: center;
}

.customer__details {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 40px;
  cursor: pointer;
}

.customer__img {
  width: 50px;
  height: 50px;
}

.customer__img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.customer__name {
  font-size: 1.2rem;
  font-weight: 500;
  background: linear-gradient(90deg, #00c853, #b2ff59); /* Green gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 992px) {
  .slider__content-top h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .slider__wrapper {
    width: 98%;
  }

  .customer__name {
    font-size: 1rem;
  }
}

/* Slider buttons */
.slider__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: var(--slider-button-bg);
  color: var(--slider-button-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
}

.slider__button--left {
  left: 10px;
}

.slider__button--right {
  right: 10px;
}

/* Light theme */
.light-theme .slider__button {
  background: #9c4eff; /* Purple background */
  color: #ffffff; /* White text */
}
.light-theme .new{
  color:black;
}
/* Dark theme */
.dark-theme .slider__button {
  background: #ffffff; /* White background */
  color: #000; /* Black text */
}
.new{
  color:white;
}