/* Contact Form CSS */

/* Original Styles, preserving as-is for larger screens */
.contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact__heading {
  font-family: var(--font-family);
  font-size: 65px;
  background: linear-gradient(to right, #4852db, #6f74dd);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.contact__subHeading {
  padding-top: 20px;
  font-family: var(--font-family);
  color: var(--color-subtext);
  font-size: 18px;
  width: 600px;
  line-height: 1.5;
  padding-bottom: 40px;
}

.contact__info__box {
  position: relative;
  width: 1250px;
  height: 650px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(40, 43, 51);
  overflow: hidden;
}

.contact__info__box::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  background-color: rgba(40, 43, 51);
  z-index: 2; /* Ensure it sits above the gradient */
}

.left-cover, .right_cover {
  position: relative;
  z-index: 3; /* Ensure content sits above the gradient */
}

.left-cover {
  padding-top: 4.5%;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact_firstRow_info,
.contact_secondRow_info,
.contact_ThirdRow_info {
  display: flex;
  gap: 50px;
}

.contact_firstRow_info p,
.contact_secondRow_info p,
.contact_ThirdRow_info p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: left;
}

.contact_firstRow_info input,
.contact_secondRow_info input,
.contact_ThirdRow_info input {
  width: 250px;
  margin-bottom: 20px;
  height: 60px;
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(19, 22, 38);
  border: 1px solid rgba(40, 43, 51, 1);
  font-size: 18px;
}

.contact_firstRow_info_message textarea {
  width: 550px;
  height: 200px;
  padding: 20px;
  background-color: rgb(19, 22, 38);
  border: 1px solid rgba(40, 43, 51, 1);
  resize: none;
  vertical-align: top;
  font-family: var(--font-family);
  font-size: 16px;
  border-radius: 10px;
}

.contact_firstRow_info_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  background-color: rgb(19, 22, 38);
  border-radius: 10px;
  width: 150.42px;
  padding:12px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 2px 1px rgba(255, 255, 255, 0.3);
  
}

.contact_ForthRow_info {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-right: 65%;
  cursor: pointer;
}

.right_cover {
  padding-top: 4.5%;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  display: flex;
  padding-left: 2.5%;
  flex-direction: column;
  text-align: left;
}

.right_cover_title {
  padding-top: 50px;
  font-size: 24px;
  font-weight: 600;
}

.right_cover_paragraph {
  padding-top: 20px;
  font-size: 18px;
  line-height: 1.5;
  width: 500px;
}

.right_cover_line {
  width: 80%;
  margin-top: 30px;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.right_cover_pointOne,
.right_cover_pointTwo,
.right_cover_pointThree {
  padding-top: 50px;
  display: flex;
  align-items: center;
}

.right_cover_pointOne img, .right_cover_pointTwo img, .right_cover_pointThree img {
  margin-right: 20px;
}

.right_cover_point_text {
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .contact__container {
    padding: 20px 10px;
  }

  .contact__info__box {
    width: 90%;
    height: auto;
    margin-top: 20px;
  }

  /* Remove the vertical line in the contact form */
  .contact__info__box::after {
    display: none;
  }

  .contact__subHeading {
    width: 90%;
  }

  .left-cover,
  .right_cover {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    display: block;
  }

  .right_cover_title {
    padding-top: 20px;
    text-align: center;
  }

  .right_cover_paragraph,
  .right_cover_line,
  .right_cover_pointOne, 
  .right_cover_pointTwo, 
  .right_cover_pointThree {
    width: 100%;
    padding-left: 0;
  }

  .right_cover_paragraph {
    padding-top: 10px;
    text-align: center;
  }

  .right_cover_line {
    margin: 10px auto;
  }

  .right_cover_pointOne, 
  .right_cover_pointTwo, 
  .right_cover_pointThree {
    justify-content: flex-start;
    padding-top: 20px;
    padding-left: 10px;
  }

  /* Align icons vertically and text on the right */
  .right_cover_pointOne, 
  .right_cover_pointTwo, 
  .right_cover_pointThree {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .right_cover_pointOne img, 
  .right_cover_pointTwo img, 
  .right_cover_pointThree img {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .contact_firstRow_info,
  .contact_secondRow_info,
  .contact_ThirdRow_info {
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }

  .contact_firstRow_info input,
  .contact_secondRow_info input,
  .contact_ThirdRow_info input,
  .contact_firstRow_info_message textarea {
    width: 90%;
  }



  .contact_ForthRow_info {
    margin-right: auto;
    width: 100%;
    justify-content: center;
  }
}

/* Styles for Thank You Message Screen */

/* Base styles for the Thank You message */
.left-cover_done_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 60px 20px; /* Adjust padding for better spacing */
}

.left-cover_checkmark {
  width: 100px; /* Ensure the image is of a reasonable size */
  height: auto;
  margin-bottom: 20px; /* Add space below the checkmark */
}

.left-cover_done_title {
  font-family: var(--font-family);
  font-size: 32px; /* Adjusted size for better readability */
  font-weight: bold;
  color: var(--contact-primary-text-color);
  margin-bottom: 10px; /* Add space below the title */
}

.left-cover_done_subparagraph,
.left-cover_done_subparagraph_two {
  font-family: var(--font-family);
  font-size: 18px; /* Adjusted size for better readability */
  color: var(--contact-secondary-text-color);
  margin-top: 5px; /* Add space above the text */
}

/* Mobile Styles */
/* Mobile Styles */
@media (max-width: 768px) {
  .contact__container {
    padding: 20px 10px;
  }

  .contact__info__box {
    width: 90%;
    height: auto;
    margin-top: 20px;
  }

  .contact__subHeading {
    width: 90%;
  }

  .left-cover,
  .right_cover {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    display: block;
  }

  .right_cover_title {
    padding-top: 20px;
    text-align: center;
  }

  .right_cover_paragraph,
  .right_cover_line,
  .right_cover_pointOne, 
  .right_cover_pointTwo, 
  .right_cover_pointThree {
    width: 100%;
    padding-left: 0;
  }

  .right_cover_paragraph {
    padding-top: 10px;
    text-align: center;
  }

  .right_cover_line {
    margin: 10px auto;
  }

  .right_cover_pointOne, 
  .right_cover_pointTwo, 
  .right_cover_pointThree {
    justify-content: flex-start;
    padding-top: 20px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .right_cover_pointOne img, 
  .right_cover_pointTwo img, 
  .right_cover_pointThree img {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .contact_firstRow_info,
  .contact_secondRow_info,
  .contact_ThirdRow_info {
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }

  .contact_firstRow_info input,
  .contact_secondRow_info input,
  .contact_ThirdRow_info input,
  .contact_firstRow_info_message textarea {
    width: 90%;
  }

  /* Center button and ReCAPTCHA vertically and align them one over the other */
  .contact_ForthRow_info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    margin-top: 20px;
    gap: 10px; /* Space between button and ReCAPTCHA */
    width: 100%; /* Ensure it takes full width */
  }

  .contact_firstRow_info_button {
    margin: 0; /* Reset margin */
    width: auto; /* Adjust width */
  }

  .recaptcha {
    margin: 0; /* Reset margin */
  }
}


/* Desktop/Large Screen Styles for Thank You Message Screen */
@media (min-width: 769px) {
  .contact_firstRow_info_button{
    margin-left: 25rem;
    margin-right: 5rem;
  }
  .recaptcha{
    height: 5px;
  }
  .left-cover_done_message {
    display: flex;
    flex-direction: column; /* Ensure the text appears below the check mark */
    align-items: center;
    justify-content: center; /* Center the content */
    text-align: center; /* Center text */
    padding: 80px 40px; /* Additional padding for larger screens */
    margin-left: -39rem;
    margin-top: 7rem;
  }

  .left-cover_checkmark {
    width: 120px; /* Slightly larger checkmark on larger screens */
    margin-bottom: 20px; /* Add bottom margin */
  }

  .left-cover_done_title {
    font-size: 36px; /* Larger font size for title on larger screens */
    margin-bottom: 10px; /* Add space below the title */
  }

  .left-cover_done_subparagraph,
  .left-cover_done_subparagraph_two {
    font-size: 20px; /* Larger font size for text on larger screens */
    margin-top: 5px; /* Add space above the text */
  }
}

/* Light theme */
.light-theme {
  --contact-primary-text-color: black;
  --contact-secondary-text-color: rgba(0, 0, 0, 0.77);
  --contact-background-color: white;
}

.dark-theme {
  --contact-primary-text-color: white;
  --contact-secondary-text-color: rgba(247, 247, 247, 0.77);
  --contact-background-color: rgb(19, 22, 38);
}

.contact__subHeading,
.contact_firstRow_info p,
.contact_secondRow_info p,
.contact_ThirdRow_info p,
.right_cover p,
.right_cover_title,
.left-cover_done_title,
.left-cover_done_subparagraph,
.left-cover_done_subparagraph_two {
  color: var(--contact-primary-text-color);
}

.right_cover_paragraph,
.bulletpoint_link {
  color: var(--contact-secondary-text-color);
}

.contact_firstRow_info input,
.contact_secondRow_info input,
.contact_ThirdRow_info input,
.contact_firstRow_info_message textarea,
.contact_firstRow_info_button {
  background-color: var(--contact-background-color);
  border: 1px solid rgba(40, 43, 51, 1);
  color: var(--contact-primary-text-color);
}

.right_cover_title {
  color: var(--contact-primary-text-color);
}
